<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">轮播图管理</a></li>
            <li class="breadcrumb-item active" id="title3">轮播图列表</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">轮播图列表</h4>
      </div>
    </div>

    <el-table :data="datalist" border stripe style="width: 100%" v-loading="listLoading" highlight-current-row
      max-height="600" size="small">
      <el-table-column prop="bimage" label="轮播图图片" width="270" align="center">
        <template #default="scope">
          <img :src="'https://www.jiuyihuishou.online/useclothingrecycling/' + scope.row.bimage"
            style="width: 250px;height: 100px" />
        </template>
      </el-table-column>
      <el-table-column prop="border" label="显示顺序" align="center"></el-table-column>
      <el-table-column label="操作" min-width="200" align="center">
        <template #default="scope">
          <el-button type="success" size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
            style=" padding: 3px 6px 3px 6px;">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete"
            style=" padding: 3px 6px 3px 6px;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page="page.currentPage" :page-size="page.pageSize"
      background layout="total, prev, pager, next, jumper" :total="page.totalCount"
      style="float: right; margin: 10px 20px 0 0"></el-pagination>

  </div>
</template>
<script>
import request, { base } from "../../../utils/http";
export default {
  name: 'banner',
  components: {

  },
  data() {
    return {

      page: {
        currentPage: 1, // 当前页
        pageSize: 10, // 每页显示条目个数
        totalCount: 0, // 总条目数
      },
      isClear: false,

      listLoading: false, //列表加载状态
      btnLoading: false, //保存按钮加载状态
      datalist: [], //表格数据  

    };
  },
  created() {
    this.getDatas();
  },


  methods: {


    // 删除轮播图
    handleDelete(index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          let url = base + "/banner/del?id=" + row.bid;
          request.post(url).then((res) => {
            this.listLoading = false;

            this.$message({
              message: "删除成功",
              type: "success",
              offset: 320,
            });
            this.getDatas();
          });
        })
        .catch(() => { });
    },

    // 分页
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getDatas();
    },

    //获取列表数据
    getDatas() {
      let para = {

      };
      this.listLoading = true;
      let url = base + "/banner/list?currentPage=" + this.page.currentPage + "&pageSize=" + this.page.pageSize;
      request.post(url, para).then((res) => {
        if (res.resdata.length > 0) {
          this.isPage = true;
        } else {
          this.isPage = false;
        }
        this.page.totalCount = res.count;
        this.datalist = res.resdata;
        this.listLoading = false;
      });
    },


    // 查看
    handleShow(index, row) {
      this.$router.push({
        path: "/BannerDetail",
        query: {
          id: row.bid,
        },
      });
    },

    // 编辑
    handleEdit(index, row) {
      this.$router.push({
        path: "/BannerEdit",
        query: {
          id: row.bid,
        },
      });
    },
  },
}

</script>
<style scoped></style>
 

